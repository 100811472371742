import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import DlgCreateScript from '../Components/Dialogue/DlgCreateScript';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from '../reduxAuth/authSlice';

function Scripts() {
    const {user}                                                          = useSelector((state) => state.auth);
    const [pharmItem, setPharmItem]                                       = useState();
    const [scriptList, setScriptListing]                                  = useState();
    const [createClientScript, setCreateClientScript]                     = useState();

    const [globalFilter, setGlobalFilter]                                 = useState('');
    const [selectedScriptsUser, setSelectedScriptsUser]                   = useState(null);

    const dispatch                                                        = useDispatch();

    useEffect(() => {
        getPharmData();
        getScriptData();
      },[scriptList])

    //////////___
    const header = (
        <div className="table-header flex justify-content-between">
          <h4 className="mx-0 my-1"></h4>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Script..." />
          </span>
        </div>
    );
    //////////___
    //////////___
    const linkMenueScriptTemplate = (rowData) => {
       return <Link to={"/menuscript/" + rowData._id} className="btn btn-rescure btsm2">View</Link> 
    }
    //////////___
    const linkExpirtyDateTemplate  = (rowData) => {
        return <span>{new Date(rowData.expiryDate).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}</span>
    }
    //////////___
    //////////___
    const linkMenueScriptSatusTextTemplate = (rowData) => {
        return <div className={"stat stat" + rowData.status.text}>{rowData.status.text}</div>
     }
     //////////___
     const createdDisplayDateTemplate = (rowData) => {        

        return <span>{new Date(rowData.createdAt).toLocaleDateString()}</span>
     }
     //////////___
    const getPharmData = async () => {
    
        try {     
            const res = await axios.get(CONSTANTS.API_URL +"pharmacy/pharmcode/"+ user.pharmacycode, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
  
            setPharmItem(res.data);            
        } catch (err) {
            console.log(err);       
            //Check if token is invalid     
            if(err.response.status === 403){
                localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                dispatch(logout());
                toast.error("Session has timeout out.")
                setTimeout(() => {
                  document.location.href("/login");
                }, 4000);
            } 
        }        
    }

    const getScriptData = async () => {
    
      try {
          const codePharm = {
            "pharmcode": user.pharmacycode
          }
          const res = await axios.put(CONSTANTS.API_URL +"scripts/list/manuscript", codePharm, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          //console.log(res.data);
          setScriptListing(res.data);  
      } catch (err) {
          console.log(err);        
      }        
  }
   
  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Scripts</h2>
      <div className="card">
         <div className="card-body">
            <div className="row-box">
                { 
                (scriptList) && (
                <DlgCreateScript 
                        setCreateClientScript={setCreateClientScript} 
                        createClientScript={createClientScript} 
                        pharmacycode={user.practicenumber}
                        person={user} 
                    />
                   )}

                   {/*
                   <Button
                        label=" + "
                        className="btn btn-rescure btncircle"
                        onClick={() => setCreateClientScript(true)}
                        />
                        */
                    }
                    <Link to="/create-script" className="btn btn-rescure ">Create Script</Link>
              
                </div>
                <div className="data-full-block hm-display ">
                   {
                        scriptList && (
                            <DataTable
                                    value={scriptList}
                                    header={header}
                                    className="min-w-full"
                                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} scripts"
                                    responsiveLayout="scroll"
                                    globalFilter={(globalFilter) ? globalFilter : ''}
                                    selection={selectedScriptsUser}
                                    onSelectionChange={e => setSelectedScriptsUser(e.value)}
                                >              
                                   <Column header="Date" body={createdDisplayDateTemplate}></Column>          
                                    <Column field="fullname" header="Full Name" sortable></Column>
                                    <Column field="scriptcode" header="Script Code"></Column>  
                                    <Column field="version" header="Version" ></Column>   
                                    <Column field="idNumber" header="ID Number"></Column>   
                                    <Column field="origScriptNumber" header="OSN"></Column>      
                                    <Column header="Valid Until" body={linkExpirtyDateTemplate}></Column>    
                                    <Column header="Status" body={linkMenueScriptSatusTextTemplate}></Column>                                                                                      
                                    <Column header="View" body={linkMenueScriptTemplate}></Column>                                                        
                            </DataTable>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Scripts