import React from 'react';
import headerLogoImage from './../../assets/headerscr.jpg';
import footerImage from './../../assets/signature.jpg';
import mainFont from './../../fonts/OpenSansFont.ttf';
import lightFont from './../../fonts/OpenSansLight.ttf';
import boldFont from './../../fonts/OpenSansBold.ttf';


import { Document, Page, pdf, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';



const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  header: {
    marginBottom: 10,
    alignItems: 'center',
  },
  headerImage: {
    width: 500, // Adjust width as needed
  },
  subhead: {
    alignItems: 'flex-end',
  },
  txtHeader: {
    
    fontSize: 10, // Adjust the font size here
    fontWeight: 300, // Light
  },
  section: {
    margin: 10,
    padding: 10,
    paddingBottom: 20, // Additional padding at the bottom
    flexGrow: 1,
  },
  txtDate: {
    fontSize: 10, // Adjust the font size here
    paddingBottom: 3, // Additional padding at the bottom
    
    fontWeight: 300,
  },
  text13: {
    fontSize: 13, // Adjust the font size here
    
    fontWeight: 700, // Bold
  },
  text12: {
    fontSize: 12, // Adjust the font size here
    
    fontWeight: 400,
  },
  text10: {
    fontSize: 10, // Adjust the font size here
    
    fontWeight: 300,
  },
  text9:{
    fontSize: 9, // Adjust the font size here    
    fontWeight: 300,
    paddingLeft:0
  },
  text7line:{
    fontSize: 7, // Adjust the font size here
    
    fontWeight: 300,
  },
  text8:{
    fontSize: 8, // Adjust the font size here    
    fontWeight: 400,
  },
  text7:{
    fontSize: 7, // Adjust the font size here    
    fontWeight: 400,
  }, 
  userList: {
    marginVertical: 10,
    fontSize: 10, //was 11 
    
    fontWeight: 400,
  },
  listItem: {
    marginBottom: 8,
  },
  listItemTitle: {
    fontWeight: 700, // Bold
  },
  listItemDose: {
    paddingLeft: 15,
  },
  listItemNoteInstruct: {
    fontSize:5
  },
  listItemNote: {
    fontSize:7,
    paddingLeft: 25,
    fontStyle: 'italic'
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 42,
    paddingRight: 20,
    pageBreakBefore: 'always',
  },
  footerLeft: {
    flex: 4, // 40% of the space
    alignItems: 'flex-start',
  },
  footerCenter: {
    flex: 1, // 10% of the space
    alignItems: 'center',
  },
  footerSignature: {
    flex: 2, // 20% of the space
    justifyContent: 'left',
  },
  footerPolicy: {
    flex: 4, // 40% of the space
    paddingHorizontal: 10,
    fontSize: 5, // Small print
    textAlign: 'justify',
  },
  footerImage: {
    width: 130, // Adjust width as needed
    height: 50, // Adjust height as needed
  },
});

function Printer({ dataScriptObject}) {
    const pageCount = Array.from(new Array(1)); // A
    const password = dataScriptObject.idNumber; 
  return (
    <pdf encryption={{ userPassword: password }}>
      <Page size="A4" style={styles.page}>
        {/* Header with image */}
        <View style={styles.header}>
          <Image style={styles.headerImage} src={headerLogoImage} />
        </View>
         {/* Header code */}
         <View style={styles.subhead}>
            <Text style={styles.txtHeader}>Script Code: {dataScriptObject.scriptcode}</Text>
            <Text style={styles.txtDate}>Valid Until: { new Date(dataScriptObject.expiryDate).toLocaleDateString()}</Text>
        </View>
        {/* Main content */}
        <View style={styles.section}>           
          <Text style={styles.text12}>Patient Name: {dataScriptObject.fullname}</Text>
          <Text style={styles.text12}>ID Number: {dataScriptObject.idNumber}</Text>
          <Text style={styles.text12}>OSN: {dataScriptObject.origScriptNumber}</Text>
          <Text style={styles.text12}>OSND: {dataScriptObject.origScriptNumDispensed}</Text>
          {
            (dataScriptObject.medical.medicalAidOption == true) && (
                <>
                    <Text style={styles.text12}>Medical Aid Number: {dataScriptObject.medical.medicalAidNumber}</Text>
                    <Text style={styles.text12}>Medical Aid Name: {dataScriptObject.medical.medicalAidName}</Text>  
                </>
              )
          }       
          {/* Display userList as a numbered list */}
          <View style={styles.userList}>
            {dataScriptObject.prescriptions.map((prescript, index) => (
              <View style={styles.listItem}>
                <Text key={index} style={styles.listItemTitle}>
                  {`${index + 1}. ${prescript.proprietaryName}`}
                </Text>
                <Text key={index} style={styles.listItemDose}>
                  {`${prescript.doseLine}`}
                </Text>
                {
                  prescript.doseNotes.length > 0 && (
                    prescript.doseNotes.map((note, index) => (
                        <View>
                            <Text key={index} style={styles.listItemNote}>
                              {`- ${note}`}
                            </Text>
                        </View>
                      )
                    )
                  )
                }                
              </View>
            ))}
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.footerLeft}>
            <Text style={styles.text7line}>{dataScriptObject.generatedTimeStamp}</Text>
            <Image style={styles.footerImage} src={footerImage} />
          </View>  
          <View style={styles.footerSignature}>
            <Text style={styles.text9}>______________________</Text>
            <Text style={styles.text8}>Signature</Text>
          </View>  
          <View style={styles.footerPolicy}>
            <Text style={styles.text7}>
             Important Infortmation
            </Text>
            <Text>
              By submitting this script, you consent to collecting, processing, and storing your personal and medical information, 
              as required by law, to fulfil your prescription and provide healthcare services. If you do not consent, will be unable to 
              process this script. An approved response from your Medical Scheme is not a guarantee of payment. 
              You are liable for any amounts not covered by your Medical Aid. 
            </Text>
          </View>
        </View>
      </Page>
      </pdf>
  );
}

export default Printer;