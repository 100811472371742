import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as CONSTANTS from "../CONSTANTS";
import { toast } from 'react-toastify';
import io from "socket.io-client";
import ScrollToBottom from "react-scroll-to-bottom";
import DlgClientDetailsUpdate from '../Components/Dialogue/DlgClientDetailsUpdate';
import { Button } from 'primereact/button';
import {  FaTrash } from "react-icons/fa";
import { logout } from '../reduxAuth/authSlice';

const socket  = io.connect(CONSTANTS.API_BASE);

function ClientDetails() {
    const {user}                                                    = useSelector((state) => state.auth);
    const [processing, setProcessing]                               = useState(false);
    
    //const enableEdit = false;

    const [showClientDetailsUpdate, setShowClientDetailsUpdate]                             = useState(false);
    const [patientDetails, setPatientDetails]                                               = useState();
    const [pharmacyDetails, setPharmacyDetails]                                             = useState();
    const [scriptDetails, setScriptDetails]                                                 = useState();

    const [currentMessage, setCurrentMessage]                           = useState("");
    const [messageList, setMessageList]                                 = useState([]);

    const [validUntilDate, setValidUntilDate]                                    = useState("");
    const [currentToken, setCurrentToken]                                        = useState();

    //If medical Aid has been disabled
    const [cashOnly, setCashOnly]                                                         = useState(false);
        /////////////////////////////////////
    const [isOriginalScript, setIsOriginalScript]                                         = useState(true);
    const [orgFNonePharmName, setOrgFNonePharmName]                                       = useState("");
    const [orgFNonePersonName, setOrgFNonePersonName]                                     = useState("");
    const [scriptNumPharm, setScriptNumPharm]                                             = useState(false);
    /*
    const [requestToJoinRoom, setRequestToJoin]                                  = useState(false);
    const [joinApproved, setJoinApproved]                                        = useState(false);
    const [chatidString, setChatidString]                                        = useState("");
    */

    const [checkForMedicalAidPayment, setCheckForMedicalAidPayment]                       = useState(true);
    const [newCheckForMedicalAidPayment,setNewCheckForMedicalAidPayment]                  = useState(true);
    const [currentPharmacy, setCurrentPharmacy]                                           = useState();
    const params                                                                          = useParams();

    const [messageCannotExtentScript, setMessageCannotExtentScript]                       = useState("");
    const [messageScriptInitial, setMessageScriptInitial]                                 = useState("");
    const [canExecuteExtension, setExecutExtension]                                       = useState(false);
    const [newExamination, setNewExamination]                                             = useState("");
    const [newExtensionExamination, setNewExtensionExamination]                           = useState("");
    
    const [searchText, setSearchText]                                                     = useState("");
    const [listSeach, setListSearch]                                                      = useState([]);
    const [showDialogSearch, setShowDialogueSearch]                                       = useState(false);
    const [prescriptions, setPrescriptions]                                               = useState([]);
    const [inValidPrescriptions, setInValidPrescriptions]                                 = useState([]);
   
    const [currentStorePricing, setCurrentStorePricing]                                   = useState("");

    //Medical items new
    const [doseLine, setDoseLine]                                                         = useState("");
    const [doseNotes, setDoseNotes]                                                       = useState([]);
    const [note, setNote]                                                                 = useState("");

    const [editSelection, setEditSelection]                                               = useState(false);
    const [selectedMedicine, setSelectedMedicine]                                         = useState(null);
    const [secondEditSelection, setSecondEditSelection]                                   = useState(1);

    const [qtyMed, setQtyMed]                                                             = useState("");
    const [dosaNumberMed, setDosaNumberMed]                                               = useState(1);
    const [periodMed, setPeriodMed]                                                       = useState("");
    const [newUnit, setNewUnit]                                                           = useState("")

    const [transferSelected, setTransferSelected]                                         = useState(0);

    const timeoutRef                                                             = useRef(null);

    const originalScriptNumberRef                                          = useRef();
    const originalScriptNumberDispensedRef                                 = useRef();

    //File of old script
    const mainOldScriptRef                                                 = useRef();

    const dispatch                                                         = useDispatch();
    
    useEffect(() => {
            if(transferSelected > 0 && selectedMedicine != null){
                //setPrescriptions((prevList) => [...prevList, selectedMedicine]);
                setInValidPrescriptions((prevList) => [...prevList, selectedMedicine]);
                ////////////////
                resetSelection();
                setProcessing(false);
            }
    },[transferSelected]);

    useEffect(() => {
        collectPatientContent();
        collectCurrenttoken();
        initializingTheDate();
        collectCurrentPricing();
        collectCurrentPharmInfo();
    },[])

    useEffect(() => {
        socket.on("receive_message", (data) => {
            setMessageList((list) => [...list, data]);
        });
    
    },[socket])
    
    const collectPatientContent = async () => {
        try{
            
            const resPatient = await axios.get(CONSTANTS.API_URL +"users/single/idnumber/"+ params.id, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });
            
            setPatientDetails(resPatient.data);
            
          //  console.log(resPatient.data);        
            
            if(resPatient.data.medical.medicalAidOption){
                setCheckForMedicalAidPayment(true);
                setNewCheckForMedicalAidPayment(true);
            } else {
                setCheckForMedicalAidPayment(false);
                setNewCheckForMedicalAidPayment(false);
            }

        }catch(err){
            console.log(err);
            //Check if token is invalid     
            if(err?.response?.status === 403){
                localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                dispatch(logout());
                toast.error("Session has timeout out.")
                setTimeout(() => {
                  document.location.href("/login");
                }, 4000);
            }
            
        }
    }

    const collectCurrentPricing = async () => {
        //setProcessing(true);
        try{
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/single",
                headers: { 
                  'token': 'Bearer ' + user.accessToken
                }
              };
              
            //))))))))
            const response = await axios.request(config);
            //))))))))
            setCurrentStorePricing(response.data.storePrice);
            setCashOnly(response.data.cashonly);
            //setProcessing(false);
        }catch(err){
            //setProcessing(false);
            console.log(err);
        }
    }

    const collectCurrentPharmInfo = async () => {
      
        try {
     
            const res = await axios.get(CONSTANTS.API_URL +"pharmacy/pharmcode/"+ user.pharmacycode, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });
    
            //console.log(res.data);
            setCurrentPharmacy(res.data);
        
          } catch (err) {
            console.log(err);             
          }        
    }

    const handleChangeSearch = (event) => {
        const txtSearch = event.target.value;

        if(txtSearch.length > 0){
            setSearchText(txtSearch);
  
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        
            // Set a new timeout
            if (txtSearch.length > 2) {
                timeoutRef.current = setTimeout(() => {
                    executeSearchNow(txtSearch);
                }, 1000); // Adjust the delay (in milliseconds) as needed
            }

        }else {
            setSearchText("");
            setListSearch([]);
            setShowDialogueSearch(false);
        }        
    }   

    const executeSearchNow = async (content) =>{
        try{
          setProcessing(true);
            
            const obSearch = {
              "searching" : content
            }
            
            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_CJMED +"medicine/search/advanced/",
                headers: { 
                  'api_key': currentToken, 
                  'Content-Type': 'application/json'
                },
                data : obSearch
              };

            //))))))))
            const resData = await axios.request(config);
            //))))))))
            // console.log(resData);
            // console.log(resData.data.length);

            if(resData.data.length > 0){
                setListSearch(resData.data);
                setShowDialogueSearch(true);
            }else {
              toast.error("Nothing found");
            }

            setProcessing(false);
        }catch(err){
          setProcessing(false);
          console.log(err);
          toast.error(err.response.data);
        }
    }

    const processCurrentSearched = (searched) => {
        /*
        const stringMedication = searched.proprietaryName + " (" + searched.activeIngredient + ") " + searched.packSize + searched.dosage +" "+ searched.unit;

        //setPrescriptions([...prescriptions, stringMedication]);
        const preScriptObject = {
            "medication" : stringMedication,
            "validateStatusText" : "Please Confirm",
            "validateProcess" : false
        }
       
        setInValidPrescriptions([...inValidPrescriptions, preScriptObject]);

        //Reset ----->>>>
        setListSearch([]);
        setShowDialogueSearch(false);
        setSearchText("");
        */
        setSelectedMedicine(searched);
        setEditSelection(true);
        setSecondEditSelection(1);
        setShowDialogueSearch(false);
    }

    const initializingTheDate = () => {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 30);
  
        const theDate = futureDate.toISOString().split('T')[0];  
        setValidUntilDate(theDate);
    }

    const viewSpecificScriptInfo = async (pharmData) => {
        
        setMessageCannotExtentScript("");
        setMessageScriptInitial("");
        setExecutExtension(false);
        const contentFetch = {
            "pharmcode" : pharmData.pharmacyCode,
            "scriptcode" : pharmData.scriptCode            
        }

        setProcessing(true);
        try{
          
            const resData = await axios.put(CONSTANTS.API_URL +"scripts/pharmacy/and/script/", contentFetch, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });
            //////
            
            setPharmacyDetails(resData.data.pharmacy);
            setScriptDetails(resData.data.script);

            if(resData.data.script.status.text === "Initialized"){
                setMessageScriptInitial("Script has only been initialized.");
            }else {
                //Variable
                //console.log(resData.data.script.expiryDate);
                const expiryDate = new Date(resData.data.script.expiryDate);
                
                // Add 168 days to the expiryDate
                const futureDate = new Date(expiryDate);
                futureDate.setDate(futureDate.getDate() + 168);

                // Get today's date
                const today = new Date();
                // Check if the futureDate is equal to or less than today's date
                if (futureDate >= today || resData.data.script.visible === false) {
                    
                    //console.log('You are restricted to make an extension');
                    setMessageCannotExtentScript("You are restricted to make an extension");
                } else {
                    //console.log('Extension applicable');
                    setExecutExtension(true);
                }                
            }
            
            setProcessing(false);
        }catch(err){
            console.log("(*)(*)");
            console.log(err);
            setProcessing(false);
        }
    }

    const requestExtentionOnThisScript = async () => {
      
        //check validate until date to see if expired
          
        //Added to the 
        var scriptObe = {
            "scriptCode" : scriptDetails.scriptcode,
            "patientid" : patientDetails._id,
            "pharmacyCode" : currentPharmacy.pharmacycode,
            "pharmName" : currentPharmacy.title,
            "extraexamination": newExamination,
            "payType" : checkForMedicalAidPayment ? "Claim"  : "Levi",
            "charge" : checkForMedicalAidPayment ?  "" : currentStorePricing,
        }
     
        if(cashOnly === true){
            scriptObe = {
                "payType" :  "Levi",
                "charge" : currentStorePricing,
            }
        }
        
        setProcessing(true);
        try{
                  
            const resData = await axios.post(CONSTANTS.API_URL +"scripts/extended/script/v2/formation", scriptObe, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });

            toast.success("New Script has been created.");
            setProcessing(false);

            setTimeout(() => {
                window.location.replace("/menuscript/" + resData.data._id);
            }, 3400);
        
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }

    const handleNewExamination = (copy) => {
        setNewExamination(copy.target.value);
    }

    const handleExaminationExtend = (copy) => {
        setNewExtensionExamination(copy.target.value);
    }

    // Function to remove a todo
    const removePrescriptF = (index) => {
        const updatedTodos = [...prescriptions];
        updatedTodos.splice(index, 1);
        setPrescriptions(updatedTodos);
    };

    // Function to remove a todo
    const removeInvalidPrescriptF = (index) => {
        const updatedTodos = [...inValidPrescriptions];
        updatedTodos.splice(index, 1);
        setInValidPrescriptions(updatedTodos);
    }
///////////////////////////////////////////////////////////////////

    const collectCurrenttoken = async () => {
        setProcessing(true);

        try{
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/single",
                headers: { 
                  'token': 'Bearer ' + user.accessToken
                }
              };
              
            //))))))))
            const response = await axios.request(config);
            //))))))))
           
            setCurrentToken(response.data.medicationToken);
            //console.log(response.data.medicationToken);
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }

    const executeConfirmCurrentMedicine = async (content, index) => {

        const newConfirm = {
            "doseLine" : content.doseLine, //String
            "proprietaryName" : content.proprietaryName, //String
            "scriptCodes": patientDetails.scriptCodes, //[Array]
        }

        try{
            setProcessing(true);

            const confirm = await axios.post(CONSTANTS.API_URL + "scripts/confirm/prescription-medicine", newConfirm, {
                    headers: {
                            'Content-Type': 'application/json',
                            token: "Bearer "+ user.accessToken
                        }
                });
           
            if(confirm.data.use === true) {
                toast.success(confirm.data.message);               
                setPrescriptions([...prescriptions, content]);
                removeInvalidPrescriptF(index);
            } else {
                toast.warning(confirm.data.message);
                removeInvalidPrescriptF(index);
            }

            setProcessing(false);
        }catch(error){
            setProcessing(false);
            console.log(error);
            toast.error("Something went wrong, please try again later.")
        }

        /*
        try{
            const resValidate = await axios.put(CONSTANTS.API_URL +"scripts/validate/medicine/item/utility", objectToSend, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });
              
                newStatus[index].validateProcess        = false;
                
                if(resValidate.data.match_status){                   
                    toast.error(resValidate.data.message);                    
                }else {                    
                    newStatus[index].validateStatusText     = "Confirmed";
                    setPrescriptions([...prescriptions, newStatus[index].medication]);                                    
                }
                //////////////////////////////////////////////////
                //take 2 seconds before removing the medication
                setTimeout(() => {
                    const updatedTodos = [...inValidPrescriptions];
                        updatedTodos.splice(index, 1);
                        setInValidPrescriptions(updatedTodos);
                }, 2500);    
        }catch(err){
            console.log(err);
        }
        */
    } 

    const handleSubmitNewMedicationPrescription = async (e) => {
        e.preventDefault();
        try{          
            
            setProcessing(true);
            var resScript = null;

            const originalScriptNotAvailable =  {
                option: !isOriginalScript,
                pharmacyName: !isOriginalScript ? orgFNonePharmName : "",
                person: !isOriginalScript ? orgFNonePersonName : "",
                acknowledge: scriptNumPharm
            }
            
            if(isOriginalScript){    
                
                const formData = new FormData();
                    formData.append("newscript", mainOldScriptRef.current.files[0]);
                    formData.append("pharmacycode", currentPharmacy.pharmacycode);  
                    formData.append("pharmName", currentPharmacy.title);  
                    formData.append("origScriptNumber", originalScriptNumberRef.current.value);
                    formData.append("origScriptNumDispensed", originalScriptNumberDispensedRef.current.value);
                    formData.append("idNumber", patientDetails.idNumber);
                    formData.append("medicalAidName", patientDetails.medical.medicalAidName);
                    formData.append("medicalAidNumber", patientDetails.medical.medicalAidNumber);
                    formData.append("medicalAidOption", patientDetails.medical.medicalAidOption ? "Yes" :"No");
                    formData.append("expirydate", validUntilDate);  
                    formData.append("extraexamination", newExtensionExamination); 
                    formData.append("payType", newCheckForMedicalAidPayment ? "Claim"  : "Levi");
                    formData.append("charge", newCheckForMedicalAidPayment ?  "" : currentStorePricing);
                    formData.append("origFileNone", JSON.stringify(originalScriptNotAvailable));

                    // Adding prescriptions array
                    prescriptions.forEach((prescription, index) => {
                        formData.append(`prescriptions[${index}][proprietaryName]`, prescription.proprietaryName);
                        formData.append(`prescriptions[${index}][applicant][name]`, prescription.applicant.name);
                        formData.append(`prescriptions[${index}][applicant][licenseNo]`, prescription.applicant.licenseNo);
                        formData.append(`prescriptions[${index}][nappiCode]`, prescription.nappiCode);
                        formData.append(`prescriptions[${index}][schedule]`, prescription.schedule);
                        formData.append(`prescriptions[${index}][activeIngredient]`, prescription.activeIngredient);
                        formData.append(`prescriptions[${index}][strength]`, prescription.strength || "");
                        formData.append(`prescriptions[${index}][unit]`, prescription.unit || "");
                        formData.append(`prescriptions[${index}][dosage]`, prescription.dosage || "");
                        formData.append(`prescriptions[${index}][packSize]`, prescription.packSize || "");
                        formData.append(`prescriptions[${index}][quantity]`, prescription.quantity || "");
                        formData.append(`prescriptions[${index}][doseLine]`, prescription.doseLine || "");
                        
                        // Adding doseNotes array for each prescription
                        prescription.doseNotes.forEach((note, noteIndex) => {
                            formData.append(`prescriptions[${index}][doseNotes][${noteIndex}]`, note);
                        });
                    });
                    
                    resScript = await axios.post(CONSTANTS.API_URL +"scripts/form/script/new/v2/upload", formData ,{
                            headers: {
                            'Content-Type': 'multipart/form-data',
                                token: "Bearer "+ user.accessToken
                            }
                        });
                        
            }else {
                    /////////If no input                                     
                    const formCreate = {
                        title: patientDetails.surname +" "+ patientDetails.name.substring(0, 1),
                        pharmacycode: user.pharmacycode,
                        origScriptNumber: originalScriptNumberRef.current.value,
                        origScriptNumDispensed: originalScriptNumberDispensedRef.current.value,
                        medicalAidOption: patientDetails.medical.medicalAidOption ? "Yes" :"No",
                        medicalAidNumber: patientDetails.medical.medicalAidNumber,
                        medicalAidName: patientDetails.medical.medicalAidName,
                        idNumber: patientDetails.idNumber,
                        name: patientDetails.name,
                        surname: patientDetails.surname,
                        gender: patientDetails.gender,
                        address: patientDetails.address,
                        expirydate: validUntilDate,
                        payType:  newCheckForMedicalAidPayment ? "Claim"  : "Levi",
                        charge: newCheckForMedicalAidPayment ? currentStorePricing : "",
                        extraexamination: newExtensionExamination,
                        phone:patientDetails.cell,
                        prescriptions: prescriptions,      
                        origFileNone: originalScriptNotAvailable,
                    }                    

                    resScript = await axios.post(CONSTANTS.API_URL +"scripts/form/script/maker", formCreate ,{
                            headers: {
                                    'Content-Type': 'application/json',
                                    token: "Bearer "+ user.accessToken
                                }
                        });
                
            }
            
            toast.success("Script has been created.");
            setProcessing(false);
           
            //console.log(resScript.data);
            
            setTimeout(() => {
                window.location.replace("/menuscript/" + resScript.data.script._id);
            }, 3400);
            
           
        }catch(err){
            console.log(err);
            setProcessing(false);
            toast.error("Something went wrong unfortunately, please try again later.");
        }
    }

    const stripDoseFromPropName = (propName) => {
        // Regular expression to match potential dose units
        const doseRegex = /\d+(?:\.\d+)?\s*(?:mg|mcg|µg|μg|g|kg|ml|l|IU|units?)/i;
    
        // Find the first match of the doseRegex
        const doseMatch = propName.match(doseRegex);
    
        // If a dose was found, remove it from the propName
        if (doseMatch) {
            return propName.replace(doseMatch[0], '').trim();
        }
    
        return propName;
    }

    const handleStopSelection = () => {
        setEditSelection(false);
        setSelectedMedicine(null);
        setSecondEditSelection(1);
    }

    // Update note value as user types
    const handleInputNoteChange = (e) => {
        setNote(e.target.value);
    }

    // Add the note to the array
    const handleAddNote = () => {
        if (note.trim() !== "") { // Check if note is not empty
            setDoseNotes([...doseNotes, note]); // Add note to the array
            setNote(""); // Clear the input field
        }
    }
    // Remove a note by index
    const handleRemoveNote = (indexToRemove) => {
        setDoseNotes(doseNotes.filter((_, index) => index !== indexToRemove));
    }

    const handleDoslineRemoval = () => {
        setDoseLine("");
        setSecondEditSelection(1);
    }

    const handleSaveDoseLine = () => {
        if(qtyMed.length > 1){
            if(dosaNumberMed > 0){
                if(periodMed.length > 1){
                    setDoseLine(qtyMed + " " + newUnit + " - " + dosaNumberMed + " times per " + periodMed);
                      
                    setQtyMed("");
                    setDosaNumberMed(1);
                    setPeriodMed("");
                    setSecondEditSelection(prev => prev + 1);
                } else {
                    toast.warning("Please add the period.")
                }
            } else {
                toast.warning("Please add the Number.")
            }
        } else {
            toast.warning("Please add the Quantity.")
        }
    }

    const resetSelection = () => {        
            setDoseLine("");
            setDoseNotes([]);
            setSearchText("");
            setSecondEditSelection(1);
            setEditSelection(false);
            setSelectedMedicine(null);    
    }

    const handleSaveMedicineToList = () => {
        setProcessing(true);
        /////////////////
        
        setSelectedMedicine((prevState) => ({
            ...prevState,
            doseLine: doseLine,
            doseNotes: doseNotes, // Add your desired value
        }));
        
        ////////////////
        setTransferSelected(prev => prev + 1);
        //^^^^
    }

  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Patient</h2>
        
        <div className="card">
            <div className="card-body">   
              {
                    patientDetails && (
                        <DlgClientDetailsUpdate 
                                showClientDetailsUpdate={showClientDetailsUpdate}
                                setShowClientDetailsUpdate={setShowClientDetailsUpdate}
                                patientDetails={patientDetails}
                                person={user} 
                            />
                    )
              }            
              {
                patientDetails && (
                    <div className="patient-box-area">
                        <div className="extend-block">
                            <h3 className="sub-sect">Patient Details</h3>
                            <div className="row ext-row">
                                <div className="col-md-4">
                                    <h4 className="small-text">Details</h4>
                                    <div className="sus-block">
                                    {patientDetails.name} {patientDetails.surname}, {patientDetails.gender}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h4 className="small-text">Address</h4>
                                    <div className="sus-block">
                                       {patientDetails.address}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    {
                                        patientDetails.medical.medicalAidOption === true && (
                                            <>
                                                <h4 className="small-text">Medical Aid</h4>
                                                    {
                                                        (patientDetails.medical.medicalAidOption === true ) && (
                                                            <div className="sus-block">
                                                                {patientDetails.medical.medicalAidName}, {patientDetails.medical.medicalAidNumber}
                                                            </div>
                                                        )
                                                    }          
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row ext-row">
                                <div className="mgtop30">
                                    <Button
                                            label="Edit Details"
                                            className="btn btn-rescure btsm1 fright"
                                            onClick={() => setShowClientDetailsUpdate(true)}
                                            />
                                </div>
                            </div>
                        </div>
                        <div className="extend-block">
                            <h3 className="sub-sect">Scripts History</h3>
                            <div className="row">                            
                                    <table className="table v-table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Pharmacy Name</th>
                                                <th>Script Code</th>
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                patientDetails.scriptPharming.map((pharm, index) => {
                                                    return <tr key={index}>
                                                        <td> {pharm.pharmName}</td>
                                                        <td> {pharm.scriptCode}</td>
                                                        <td> <button className="btn btn-rescure btsm1" onClick={() => viewSpecificScriptInfo(pharm)}>View</button></td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>                                
                            </div>
                        </div>
                        <div className="sect-box">                        
                            <div className="view-tabular flexme">
                                    {
                                        scriptDetails && (
                                            <div className="flexfit50 chat-area-request-chat">
                                                <h3>Script</h3>
                                                <table className="table tb-small">
                                                    <tbody>
                                                        <tr>
                                                            <td>Script Code</td>
                                                            <td>:</td>
                                                            <td>{scriptDetails.scriptcode}</td>
                                                        </tr>                                                        
                                                        <tr>
                                                            <td>Valid Until</td>
                                                            <td>:</td>
                                                            <td>{
                                                                    new Date(scriptDetails.expiryDate).toLocaleDateString(undefined, {
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Medication</td>
                                                            <td>:</td>
                                                            <td>
                                                                {
                                                                    scriptDetails.prescriptions && (
                                                                        <ul>
                                                                        {
                                                                            scriptDetails.prescriptions.map((meds, index) => {
                                                                                return <li key={index}>
                                                                                        <div className="content-scr-dt-in">
                                                                                            <div className="litext">{meds.proprietaryName}</div>
                                                                                            <div className="instruct">Instruction:</div> 
                                                                                            <span className="ds-view-details">{meds.doseLine}</span>
                                                                                            <div className="list-notes">
                                                                                                {
                                                                                                    meds.doseNotes.length > 0 && (
                                                                                                        <ul className="notes-sub-display">
                                                                                                        {
                                                                                                            meds.doseNotes.map((note, index) => {
                                                                                                            return <li key={index} className="note-item">
                                                                                                                {note}
                                                                                                            </li>
                                                                                                            })
                                                                                                        }
                                                                                                        </ul>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                })                                                  
                                                                        }
                                                                        </ul>
                                                                    )
                                                                }                                                                
                                                            </td>
                                                        </tr>                                                   
                                                        <tr>
                                                            <td>Payment</td>
                                                            <td>:</td>
                                                            <td>{scriptDetails.payment.payType}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div div className="mgtop30">

                                                {
                                                    ( messageCannotExtentScript.length > 0 ) && (
                                                        <div className="alert alert-danger">
                                                            {messageCannotExtentScript}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    messageScriptInitial.length > 0 && (
                                                        <div className="alert alert-warning">
                                                            {messageScriptInitial}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    canExecuteExtension && (
                                                        <div className="min-request">
                                                            <div className="form-group">
                                                                <div className="lbl-field">If any additional test was conducted. <sub>Optional</sub></div>
                                                                <textarea className="form-control" onChange={handleNewExamination} placeholder="Examination...?"></textarea>
                                                            </div>
                                                            <div className="form-group">
                                                            
                                                                    {
                                                                            (patientDetails.medical.medicalAidOption === true) ? (
                                                                                <div className="ch-box">
                                                                                    <div className="small-text">Please check to pay via medical aid.</div>
                                                                                    <div className="checkbox-item-left">
                                                                                        <input 
                                                                                            type="checkbox"
                                                                                            name="check"
                                                                                            checked={checkForMedicalAidPayment}
                                                                                            onChange={() => setCheckForMedicalAidPayment(!checkForMedicalAidPayment)}
                                                                                            />
                                                                                    </div>
                                                                                    <div className="chck-content">
                                                                                        {
                                                                                            checkForMedicalAidPayment ? "Claim with Medical Aid" : "Levi - Cash Payment (R " + currentStorePricing+")"
                                                                                        }
                                                                                        
                                                                                    </div>                                                                        
                                                                                </div>
                                                                            ) 
                                                                            : 
                                                                            <div className="chck-content">
                                                                            Levi - Cash Payment ({currentStorePricing})
                                                                            </div>
                                                                        }
                                                                
                                                            
                                                            </div>
                                                            <button onClick={() => requestExtentionOnThisScript()} className="btn btn-green">
                                                                Request Extension
                                                            </button>
                                                        </div>
                                                    )
                                                }            
                                            </div>
                                        </div>
                                        )
                                    }
                                    
                                    {
                                            pharmacyDetails && (
                                                <div className="flexfit50 chat-area-info">
                                                    <div className="adding-mask">
                                                        <h3>Pharmacy</h3>
                                                        <table className="table tb-small">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Pharmacy Code</td>
                                                                    <td>:</td>
                                                                    <td>{pharmacyDetails.pharmacycode}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Pharmacy Name</td>
                                                                    <td>:</td>
                                                                    <td>{pharmacyDetails.groupdetail}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Label</td>
                                                                    <td>:</td>
                                                                    <td>{pharmacyDetails.title}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Pharmacy Group</td>
                                                                    <td>:</td>
                                                                    <td>{pharmacyDetails.grouptype}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Phone</td>
                                                                    <td>:</td>
                                                                    <td>{pharmacyDetails.phone}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>    
                                                </div>
                                            )
                                    }                                    
                             </div>
                        </div>

                        <div className="sect-box">
                            <h3 className="sub-sect">New Script</h3>
                            <h3>Create a new Script</h3>
                            <div className="row script-creator-extend">
                                <p>
                                    You may create a new script extending another expired prescription. Bare in mind, it cannot have the same 
                                    medication as scripts that are under restrition.
                                </p>
                                <div className="col-md-6">
                                    <div className="mgtop30">                                  
                                            <div className="form-group">
                                                <div className="sm-label">Add Medication</div>
                                                 <div className="selected-area">
                                                        {
                                                            editSelection && (
                                                                    <div className="edit-selected">
                                                                        <div className="medicine-content">
                                                                                    <div className="medicine-name">
                                                                                        {stripDoseFromPropName(selectedMedicine.proprietaryName)}
                                                                                    </div>
                                                                                
                                                                                    {
                                                                                        doseLine.length > 1 && (
                                                                                            <div className="doseline-view">
                                                                                                <span className="ds-line-show"> {doseLine}</span>
                                                                                                <button
                                                                                                    type="button" onClick={() => handleDoslineRemoval()} 
                                                                                                    className="btn-gray-sm trash-line" >
                                                                                                        <FaTrash />
                                                                                                    </button>                                                                           
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div className="dos-input-outer">
                                                                                        <div className="dos-input-perif">
                                                                                                <div className="mditem md1">
                                                                                                    <div className="lbl-prep">
                                                                                                    Amount (Qty)
                                                                                                    </div>
                                                                                                    <input 
                                                                                                        type="number" 
                                                                                                        className="ct-in quantity-item"
                                                                                                        placeholder="500.2"
                                                                                                        value={qtyMed}
                                                                                                        onChange={(e) => setQtyMed(e.target.value)}
                                                                                                        />
                                                                                                </div>
                                                                                                <div className="mditem md2">
                                                                                                    <div className="lbl-prep">
                                                                                                        Unit
                                                                                                    </div>
                                                                                                    <input 
                                                                                                        type="text" 
                                                                                                        className="ct-in unit-item"
                                                                                                        placeholder="ml/cap..."
                                                                                                        value={newUnit}
                                                                                                        onChange={(e) => setNewUnit(e.target.value)}
                                                                                                        />
                                                                                                </div>
                                                                                                <div className="mditem md3">
                                                                                                <div className="lbl-prep">
                                                                                                        Frequency
                                                                                                    </div>
                                                                                                    <input 
                                                                                                        type="number" 
                                                                                                        className="ct-in freq-amt" 
                                                                                                        value={dosaNumberMed}
                                                                                                        onChange={(e) => setDosaNumberMed(e.target.value)}
                                                                                                        />
                                                                                                </div>
                                                                                                <div className="mditem md4"> 
                                                                                                    <div className="lbl-prep">
                                                                                                            
                                                                                                    </div>
                                                                                                    <div className="center-part">
                                                                                                        per
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mditem md5">
                                                                                                    <div className="lbl-prep">
                                                                                                            Period
                                                                                                        </div>
                                                                                                        <input 
                                                                                                            type="text" 
                                                                                                            className="ct-in period-amt" 
                                                                                                            placeholder="day/hour..."
                                                                                                            value={periodMed}
                                                                                                            onChange={(e) => setPeriodMed(e.target.value)}
                                                                                                        />
                                                                                                </div>
                                                                                        </div>
                                                                                        <div className="save-dose-line">
                                                                                            <button 
                                                                                                type="button"
                                                                                                className="btn btn-save-dose" 
                                                                                                onClick={() => handleSaveDoseLine()}>
                                                                                                <span>Confirm</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                    <div className="extra-note-area">
                                                                                        <div className="notes-list">
                                                                                            {
                                                                                                doseNotes.length > 0 && (
                                                                                                    <ul className="med-notes">
                                                                                                        {
                                                                                                            doseNotes.map((note, index) => {
                                                                                                                return <li key={index} className="note-item">
                                                                                                                    <span> {note}</span>
                                                                                                                    <button 
                                                                                                                        type="button" 
                                                                                                                        onClick={() => handleRemoveNote(index)} className="btn-gray-sm trash-line">
                                                                                                                            <FaTrash /></button>
                                                                                                                </li>
                                                                                                            })
                                                                                                        }
                                                                                                    </ul>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                        <div className="add-note">
                                                                                            Add Note
                                                                                        </div>
                                                                                        <input type="text" 
                                                                                            className="note-input" 
                                                                                            placeholder="e.g After meals"
                                                                                            value={note}
                                                                                            onChange={handleInputNoteChange} // Track input changes
                                                                                            /><button type="button" onClick={handleAddNote}>+</button>
                                                                                    </div>
                                                                                    <div className="foot-selections-meds ">
                        
                                                                                        <button 
                                                                                            type="button" 
                                                                                            className="btn-gray-sm"
                                                                                            onClick={() => handleStopSelection()}>
                                                                                                <FaTrash /> 
                                                                                        </button>
                        
                                                                                        {
                                                                                            secondEditSelection > 1 && (
                                                                                                <button 
                                                                                                    type="button"
                                                                                                    className="btn-circular" 
                                                                                                    onClick={() => handleSaveMedicineToList()}>
                                                                                                    Save Medicine to List</button>)
                                                                                        }
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                            )
                                                        }
                                                    </div>

                                                <div className="search-box">
                                                    <input 
                                                        type="text"
                                                        className="searchMain"
                                                        onChange={handleChangeSearch}
                                                        placeholder="Add Search text, 2 characters or more..."
                                                        value={searchText}
                                                        />
                                                </div>
                                                {
                                                    showDialogSearch && (
                                                        <div className="dialog-search">
                                                            {
                                                                listSeach.map((searched, index) => {
                                                                return <div className="content-search" key={index} onClick={() => processCurrentSearched(searched)}> 
                                                                        <strong>{searched.proprietaryName}</strong> 
                                                                        <span className="mgright5">({searched.activeIngredient}) </span> 
                                                                        <span className="thin mgright5"> {searched.dosage}</span> 
                                                                        <span className="smaller thin">({searched.unit})</span> 
                                                                    </div>
                                                                })        
                                                            }     
                                                        </div>
                                                    )
                                                }
                                            </div>
                                    </div>
                                    <div className="block">
                                    <h4>Confirmed Medication List</h4>
                                    <ul className="list-items-cont">
                                            {
                                                prescriptions.map((prescript, index) => (
                                                    <li key={index}>
                                                        <span className="litext">{prescript.proprietaryName}</span>
                                                        <button type="button" onClick={() => removePrescriptF(index)} className="btn btn-close-ls">X</button>
                                                    </li>
                                                    )
                                                )
                                            }
                                    </ul>
                                    </div>
                                    <div className="form-group">
                                        <div className="sm-label">Add Examination <sub>Optional</sub></div>
                                        <textarea className="form-control" onChange={handleExaminationExtend}></textarea>
                                    </div>
                                    <div className="form-group">
                                            {
                                                cashOnly === true ?
                                                    <>
                                                        <div className="chck-content">
                                                            Levi - Cash Payment ({currentStorePricing})
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (patientDetails.medical.medicalAidOption === true) ?(
                                                                <div className="ch-box">
                                                                    <div className="small-text">Please check to pay via medical aid.</div>
                                                                    <div className="checkbox-item-left">
                                                                        <input 
                                                                            type="checkbox"
                                                                            name="check"
                                                                            checked={newCheckForMedicalAidPayment}
                                                                            onChange={() => setNewCheckForMedicalAidPayment(!newCheckForMedicalAidPayment)}
                                                                            />
                                                                    </div>
                                                                    <div className="chck-content">
                                                                        {
                                                                            newCheckForMedicalAidPayment ? "Claim with Medical Aid" : "Levi - Cash Payment (R " + currentStorePricing+")"
                                                                        }                                                            
                                                                    </div>                                                                        
                                                                </div>
                                                            ) 
                                                            : 
                                                            <div className="chck-content">
                                                                Levi - Cash Payment ({currentStorePricing})
                                                            </div>
                                                        }
                                                    </>
                                            }                                            
                                            </div>
                                </div>
                                <div className="col-md-6">
                                
                                        <p className="pd20">Please validate the selected medicine, by clicking the confirm 
                                            button. Only medicine that is not in a patients current <strong>running</strong> ResQ-Script is viable for confirmation.</p>
                                        <div className="block">
                                        <ul className="list-items-cont">
                                            {
                                                inValidPrescriptions.map((prescript, index) => (
                                                    <li key={index} className="groove-container">
                                                        <div className="litext">{prescript.proprietaryName}</div>
                                                        <div className="validator-buttons">
                                                            
                                                            <div className="cooreview-content">
                                                                    <div className="doseline-view">
                                                                        {prescript.doseLine}
                                                                    </div>
                                                                    <div className="notes-list">
                                                                        {
                                                                        prescript.doseNotes.length > 0 && (
                                                                                <ul className="med-notes">
                                                                                    {
                                                                                        prescript.doseNotes.map((note, index) => {
                                                                                            return <li key={index} 
                                                                                                     className="note-item">
                                                                                                    {note}                                                                                           
                                                                                                    </li>
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            )
                                                                        }
                                                                    </div>        
                                                            </div>

                                                            <div className="short-info-peri">
                                                                <span>{prescript.activeIngredient}</span>
                                                                <span>({prescript.strength})</span>
                                                                <span className="circle-sch mgleft10">{prescript.schedule}</span>
                                                            </div>

                                                            <div className="level-up-buttns">
                                                                <button 
                                                                    type="button"
                                                                    className="btn btn-danger pots-work" 
                                                                    onClick={() => removeInvalidPrescriptF(prescript)}>
                                                                        <FaTrash />
                                                                </button> 
                                                                <button 
                                                                    type="button"
                                                                    className="btn btn-warning mgtop10" 
                                                                    onClick={() => executeConfirmCurrentMedicine(prescript, index)}>
                                                                        Confirm
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                            </ul>
                                        </div>
                                </div>                              
                            </div>
                             
                            {
                                (prescriptions.length > 0) && (inValidPrescriptions.length === 0) && (
                                    <div className="col-md-6">
                                        <div className="pd10 f-full">
                                            <form onSubmit={handleSubmitNewMedicationPrescription}>  
                                                <div className="form-group">
                                                    <div className="sm-label">Original Script Number (OSN)*</div>
                                                    <input
                                                        type="text"                                
                                                        placeholder="Enter Original Script Number"
                                                        ref={originalScriptNumberRef}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <div className="sm-label">Original Script Number Dispensed (OSND)*</div>
                                                    <input
                                                        type="text"                                
                                                        placeholder="Enter Expired Dispensed Script Number"
                                                        ref={originalScriptNumberDispensedRef}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>

                                                <div className="script-assessment">
                                                    <div className="button-group">
                                                        {/* Buttons for toggling */}
                                                        <button
                                                            type="button"
                                                            className={`btn ${isOriginalScript ? "active" : ""}`}
                                                            onClick={() => setIsOriginalScript(true)}
                                                        >
                                                            I have the original script
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`btn ${!isOriginalScript ? "active" : ""}`}
                                                            onClick={() => setIsOriginalScript(false)}
                                                        >
                                                            I don't have the original script
                                                        </button>
                                                    </div>

                                                    {/* Form group based on the selected button */}
                                                        {isOriginalScript ? (
                                                            <div className="form-group">
                                                            <div className="sm-label">Original Script Upload if present</div>
                                                            <input
                                                                type="file"
                                                                accept="image/jpeg,image/png,application/pdf"
                                                                placeholder="Enter Old Script Document"
                                                                ref={mainOldScriptRef}
                                                                className="form-control"
                                                                required
                                                            />
                                                            </div>
                                                        ) : (
                                                            <div className="form-group sc-verify">
                                                                
                                                                <div className="sm-label ">Pharmacy Name*</div>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter Pharmacy Name"
                                                                        className="form-control"
                                                                        onChange={(e) => setOrgFNonePharmName(e.target.value)}
                                                                        required
                                                                    />

                                                                <div className="sm-label lb-2">Pharmacist Name who confirmed possession of original script</div>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter Contact Name"
                                                                        className="form-control"
                                                                        onChange={(e) => setOrgFNonePersonName(e.target.value)}
                                                                    />

                                                                <div className="checkbox-group">
                                                                    <div className="acknowledge-script">
                                                                        By proceeding with the next step, I hereby acknowledge that I have verified the 
                                                                        existence and authenticity of the original prescription, confirmed the dosage 
                                                                        form and usage instructions as indicated by the prescribing healthcare 
                                                                        professional. The contact indicated, confirmed that the prescription is in the 
                                                                        possession of the pharmacy stated above.
                                                                    </div>
                                                                    <label className="ch-item">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={scriptNumPharm}
                                                                            onChange={(e) => setScriptNumPharm(e.target.checked)}
                                                                        />

                                                                        <span>I Confirm</span>
                                                                    </label>                                                                    
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                <div className="form-group">
                                                    <div className="sm-label">Valid Until</div>
                                                    <input
                                                        type="text"
                                                        placeholder="Expiry Date (30 days)"
                                                        value={validUntilDate}  
                                                        className="form-control"
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="pd10">
                                                    <button  className="btn btn-rescure" >Submit</button>                            
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    )
                            }
                        </div>          
                    </div>
                )
               }
            </div>
        </div>
          {
                processing && (
                    <div className="img-source">
                         <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                    </div>
                )
           }
    </div>
  )
}

export default ClientDetails